/** @jsxImportSource theme-ui */
import * as React from "react";
import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import { Button } from "theme-ui";

const CTA = ({ cta, ...props }) => {
  if (cta.linkType === "anchor") {
    return (
      <AnchorLink to={`/#${cta.anchorLink}`} title={cta.title}>
        <Button {...props}>{cta.title}</Button>
      </AnchorLink>
    );
  }
  if (cta.linkType === "external") {
    return (
      <a href={cta.href} target={cta.blank ? `_blank` : null} rel="noreferrer">
        <Button {...props}>{cta.title}</Button>
      </a>
    );
  }
  if (cta.linkType === "internal") {
    return (
      <Link sx={{ textDecoration: "none" }} to={cta.slug.current}>
        <Button {...props}>{cta.title}</Button>
      </Link>
    );
  }
  return null;
};

export default CTA;
