/** @jsxImportSource theme-ui */
import * as React from "react";
import imageUrlBuilder from "@sanity/image-url";
import client from "../api-sanity";

const builder = imageUrlBuilder(client);

function urlFor(source) {
  return builder.image(source);
}

const Image = ({ className, source }) => {
  if (source !== undefined) {
    return <img alt="" className={className} src={urlFor(source).url()} />;
  }
  return null;
};

export default Image;
